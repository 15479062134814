<template>
  <div class="myFileList flex">
    <div class="upload-image-list">
      <draggable
        class="syllable_ul"
        element="ul"
        :list="fileList"
        :options="{ group: 'title', animation: 150 }"
        :no-transition-on-drag="true"
      >
        <transition-group
          type="transition"
          class="flex"
          :name="!drag ? 'syll_li' : null"
          :css="true"
        >
          <div class="upload-image-item" v-for="(item, index) in fileList" :key="item.uid">
            <a-icon
              @click="removeCoupon(index)"
              class="close flex-c-c"
              type="close"
              v-if="!disabled"
            />
            <a-spin
              :spinning="
                !(item.url || (item.response && item.response.result && item.response.result.url))
              "
            >
              <img
                :src="
                  item.url || (item.response && item.response.result && item.response.result.url)
                "
                alt="img"
                class="upload-image"
                @click="
                  handlePreview(
                    item.url || (item.response && item.response.result && item.response.result.url)
                  )
                "
              />
            </a-spin>
          </div>
        </transition-group>
      </draggable>
    </div>
    <a-upload
      v-show="fileList.length < multiple"
      :action="uploadPartyFile"
      :headers="{ 'Authorization': token }"
      :multiple="true"
      :accept="accept"
      list-type="picture-card"
      :file-list="fileList"
      :data="{ type: 'image' }"
      :disabled="disabled"
      :showUploadList="false"
      :before-upload="beforeUpload"
      @change="handleChange"
    >
      <a-icon type="plus" />
      <div class="ant-upload-text">Upload</div>
    </a-upload>
    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
<script>
import api from '@service/api'
import draggable from 'vuedraggable'
import { Spin } from 'ant-design-vue'
import { getCookie } from '@/common/cookie'
export default {
  name: 'uploadImage',
  props: {
    multiple: {
      type: Number,
      default: 1,
    },
    imageList: {
      type: Array,
      default() {
        return []
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    needSizeCheck: {
      //需要尺寸检测
      type: Boolean,
      default: true,
    },
    propsAccept: {
      type: Array,
      default: () => ['image/jpg', 'image/jpeg', 'image/png', 'image/bmp'],
    },
  },
  data() {
    return {
      drag: false,
      previewVisible: false,
      previewImage: '',
      fileList: [],
    }
  },
  inject: ['page'],
  computed: {
    uploadPartyFile() {
      let cpid = this.$store.getters.partyId
      let url = this.page === 'platformGoodsDetail' ? api.uploadLocal : api.uploadPartyFile
      return url + '?cpid=' + cpid
    },
    token() {
      return getCookie('d_session_id')
    },
    accept() {
      return this.propsAccept.join(',')
    },
  },
  watch: {
    imageList: {
      handler(newVal) {
        this.fileList = newVal
      },
      deep: true,
    },
  },
  components: {
    draggable,
    'a-spin': Spin,
  },
  mounted() {
    this.fileList = [...this.imageList]
  },
  methods: {
    handleChange({ file, fileList }) {
      this.fileList = fileList
      if (file) {
        this.$nextTick(() => {
          if (file.response) {
            this.fileList.forEach((item) => {
              if (item.response && item.response.result) {
                this.$set(item, 'url', item.response.result.url)
                this.$set(item, 'partyFileId', item.response.result.partyFileId)
              }
            })
            this.$nextTick(() => {
              this.$emit('update:imageList', this.fileList)
            })
            this.$nextTick(() => {
              this.$emit('changeList', 'speed')
            })
          }
        })
      }
    },
    beforeUpload(file) {
      console.log('file: ', file)
      const isJPG = this.propsAccept.includes(file.type)
      if (!isJPG) {
        this.$notification['error']({
          message: '请上传图片文件',
        })
      }
      // const isLt2M = file.size / 1024 < 200 && file.size / 1024 > 10;
      // if (!isLt2M) {
      //   this.$error('文件大小应在10KB~20KB之间');
      // }
      // return isJPG && isLt2M
      const isSize = this.isSize(file)
      return isJPG && isSize
    },
    //检测尺寸
    isSize(file) {
      if (!this.needSizeCheck) {
        return Promise.resolve()
      }
      let img
      return new Promise((resolve, reject) => {
        //min 500x500 max 2000x2000
        let _URL = window.URL || window.webkitURL
        img = new Image()
        img.onload = function () {
          let valid =
            img.width >= 500 && img.width <= 2000 && img.height >= 500 && img.height <= 2000
          valid ? resolve() : reject()
        }
        img.src = _URL.createObjectURL(file)
      }).then(
        () => {
          return file
        },
        () => {
          this.$error(
            `${file.name}图片尺寸（${img.width}x${img.height}）不符合要求，请修改后重新上传！`
          )
          return Promise.reject()
        }
      )
    },
    removeCoupon(index) {
      // console.log(index, 3333)
      if (this.disabled) return
      this.fileList.splice(index, 1)
      this.$nextTick(() => {
        this.$emit('update:imageList', this.fileList)
      })
    },
    handlePreview(url) {
      // console.log(1111)
      this.previewImage = url
      this.previewVisible = true
    },
  },
}
</script>
<style lang="scss" scoped>
ul {
  padding: 0;
}
// 因为我们用的ant的组件的class，会有些默认样式要去掉，如果样式是你自己写的，那就不需要这块了
.ant-upload-list {
  display: block !important;
}
.upload-image-list {
  overflow-x: auto;
  .upload-image-item {
    width: 100px;
    height: 100px;
    position: relative;
    margin-right: 14px;
    margin-bottom: 10px;
    .close {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 2px;
      right: 2px;
      font-size: 10px;
      z-index: 99;
      opacity: 1;
      cursor: pointer;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: 100%;
    }
    .upload-image {
      width: 100px;
      height: 100px;
      object-fit: cover;
    }
  }
}
</style>
