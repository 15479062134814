<template>
  <div class="publish-modal-input">
    <template v-if="!modify">
      <span>{{ localValue }}</span>
    </template>
    <template v-else-if="type === 'singleSelect'">
      <a-select
        mode="default"
        :show-search="true"
        v-model="localValue"
        :filter-option="filterOption"
        :disabled="disabled"
        placeholder="singleSelect"
        style="width: 100%"
        @change="onChange"
      >
        <a-select-option v-for="item in options" :key="item.name">
          {{ item.name }}
        </a-select-option>
      </a-select>
    </template>
    <template v-else-if="type === 'multiSelect'">
      <a-checkbox-group
        :disabled="disabled"
        :options="optionsList"
        v-model="localValue"
        @blur="onChange"
      />
    </template>
    <template v-else-if="type === 'multiEnumInput'">
      <a-select
        mode="tags"
        style="width: 100%"
        placeholder="multiEnumInput"
        :disabled="disabled"
        v-model="localValue"
        @blur="onChange"
      >
        <a-select-option v-for="item in options" :key="item.name">
          {{ item.name }}
        </a-select-option>
      </a-select>
    </template>
    <template v-else-if="type === 'enumInput'">
      <a-auto-complete
        :data-source="optionsList"
        style="width: 100%"
        placeholder="enumInput"
        :filter-option="false"
        :disabled="disabled"
        @blur="onChange"
        v-model="localValue"
      />
    </template>
    <template v-else-if="type === 'text'">
      <a-input
        style="width: 100%"
        v-model="localValue"
        placeholder="input text"
        @blur="onChange"
        :disabled="disabled"
      ></a-input>
    </template>
    <template v-else-if="type === 'richText'">
      <quill-editor
        v-model="localValue"
        :ref="name"
        :disabled="disabled"
        :options="editorOption"
      ></quill-editor>
    </template>
    <template v-else-if="type === 'numeric'">
      <a-input
        style="width: 100%"
        v-model="localValue"
        @blur="onChange"
        placeholder="input number"
        :disabled="disabled"
        type="number"
      ></a-input>
    </template>
    <template v-else-if="type === 'switch'">
      <a-switch
        :checked="localValue === 'active'"
        @change="changeSwitch(localValue)"
        :disabled="disabled"
        style="margin-bottom: 5px"
      />
    </template>
    <template v-else-if="type === 'date'">
      <date-range-picker
        style="width: 100%"
        :needhms="true"
        :start-time="localValue"
        :singleDatePicker="true"
        @get_date="getTime"
        :disabled="disabled"
      ></date-range-picker>
    </template>
    <template v-else-if="type?.indexOf('img') > -1">
      <upload-image
        :disabled="disabled"
        :multiple="type === 'img' ? 1 : 8"
        :imageList.sync="localValue"
      ></upload-image>
      <div class="tips">{{ $t('尺寸在500×500到2000×2000px之间') }}</div>
    </template>
    <a-upload
      class="hide"
      :multiple="true"
      :action="lazadaImgReqUrl"
      :headers="{ Authorization: token }"
      accept="image/jpg,image/jpeg,image/png"
      list-type="picture-card"
      :data="{ type: 'image', shopCode: this.shopCode }"
      @change="handleUploadChange"
    >
      <div class="ant-upload-text" :id="name + 'Upload'">Upload</div>
    </a-upload>
  </div>
</template>

<script>
import api from '@service/api'
import dateRangePicker from '@component/dateRangePicker'
import uploadImage from '@component/uploadImage'
import { getCookie } from '@/common/cookie'
// 工具栏配置
const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote', 'code-block'],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  [{ direction: 'rtl' }], // text direction

  [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],
  ['link', 'image', 'video'],
  ['clean'], // remove formatting button
]
export default {
  name: 'publishModalInput',
  components: { dateRangePicker, uploadImage },
  provide: {
    page: '',
  },
  // props: ["modify", "options", "type", "value", "name", "disabled"],
  props: {
    modify: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    type: String,
    name: String,
    value: [String, Array, Number],
    disabled: Boolean,
    shopCode: [String, Number],
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              image: (value) => {
                if (value) {
                  if (this.name === 'short_description') {
                    this.$warning('short_description can not upload image')
                    return
                  }
                  this.operate_quill = this.name
                  document.getElementById(this.name + 'Upload').click()
                } else {
                  this.quill.format('image', false)
                }
              },
            },
          },
        },
      },
      operate_quill: '',
    }
  },
  computed: {
    token() {
      return getCookie('d_session_id')
    },
    lazadaImgReqUrl() {
      let cpid = this.$store.getters.partyId
      return api.uploadLocal + '?cpid=' + cpid
    },
    localValue: {
      get() {
        let _val = ''
        if (this.type === 'multiEnumInput' || this.type === 'multiSelect') {
          _val = (this.value && this.value.split(',')) || []
        } else if (this.type === 'img') {
          _val = this.value
            ? [
                {
                  uid: '-1',
                  name: `image0.png`,
                  status: 'done',
                  url: this.value,
                },
              ]
            : []
        } else if (this.type === 'imgs') {
          _val = this.value?.map((item, index) => ({
            uid: index,
            name: `image${index}.png`,
            status: 'done',
            url: item,
          }))
        } else if (this.type === 'switch') {
          _val = this.value || null
        } else {
          _val = this.value || null
        }
        return _val
      },
      set(val) {
        this.emit(val)
      },
    },
    optionsList() {
      return this.options.map((item) => item.name)
    },
  },
  methods: {
    onChange() {
      this.$nextTick(() => {
        this.$emit('changeList', 'speed')
      })
    },
    changeSwitch(value) {
      if (value === 'active') {
        this.localValue = 'inactive'
      } else {
        this.localValue = 'active'
      }
    },
    getTime(date) {
      this.localValue = date
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
      )
    },
    emit(val) {
      let emitValue = val
      if (this.type === 'img') {
        if (val.length) {
          emitValue = val[0].url
        } else {
          emitValue = ''
        }
      } else if (this.type === 'imgs') {
        emitValue = val.map((item) => item.url)
      } else if (Array.isArray(emitValue)) {
        emitValue = emitValue.join(',')
      }
      this.$emit('update:value', emitValue)
      this.$emit('changeValue', emitValue)
    },
    handleUploadChange({ file }) {
      // 获取富文本组件实例
      this.$nextTick(() => {
        if (this.operate_quill) {
          let quill = this.$refs[this.operate_quill].quill
          if (file) {
            this.$nextTick(() => {
              // 如果上传成功
              if (file.response) {
                let url = file.response.result.url
                // 获取光标所在位置
                let length = quill.getSelection().index
                // 插入图片，res为服务器返回的图片链接地址
                quill.insertEmbed(length, 'image', url)
                // 调整光标到最后
                quill.setSelection(length + 1)
              }
            })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@css/mixin.scss';
.publish-modal-input {
  width: 100%;
}
.hide {
  visibility: hidden;
  height: 0;
  opacity: 0;
  position: absolute;
  top: -100%;
  left: -100%;
}
.tips {
  font-size: 12px;
  color: $font-color-light;
}
</style>

<style lang="scss">
.ql-toolbar.ql-snow {
  line-height: normal;
}
.ql-editor {
  min-height: 100px;
}
</style>
